<template>
  <div class="main">
    <loading style="margin-top:-40px" v-if="status"></loading>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import loading from "@/components/effects/loading";
import { goWxConfigJsApi } from "@/utils/wxpay";
export default {
  components: { loading },
  data() {
    return {
      status: true
    }
  },
  mounted() {
    goWxConfigJsApi('scanQRCode');
    setTimeout(() => {
      this.status = false;
      this.wxScan();
    }, 1200)
  },
  methods: {
    wxScan() {
      wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          console.log(result);
        },
        fail: function(err) {
          console.log(err);
        }
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
</style>